<template>
    <div class="language-selection">
        <button class="secondary-input" @click="$emit('changeLanguage', 'fr')" :class="{ active: selectedLanguage === 'fr' }">FR</button>
        <button class="secondary-input" @click="$emit('changeLanguage', 'de')" :class="{ active: selectedLanguage === 'de' }">DE</button>
    </div>
</template>

<script>
export default {
    props: ['selectedLanguage'],
};
</script>

<style lang="scss" scoped>
.language-selection {

    .secondary-input {
        margin-right: 0.4em;
        margin-left: 0.4em;
    }
}
</style>