<template>
  <div class="avatar-container">

    <LanguageSelector :selectedLanguage="selectedLanguage" @changeLanguage="changeLanguage" />

    <AvatarDisplay class="avatar-display" :avatar="selectedAvatar" :accessory="selectedAccessory" />

    <!-- Champ de sélection du pseudo -->
    <div class="pseudo-container">
      <input id="pseudo" class="primary-input" type="text" v-model="pseudo" @input="validatePseudo" maxlength="16"
        :placeholder="translations[selectedLanguage].placeholder" />
    </div>

    <div class="school-dropdown">
      <select id="school" class="primary-input" v-model="selectedSchool">
        <option value="" disabled selected hidden>{{ translations[selectedLanguage].schoolPlaceholder }}</option>
        <option v-for="(school, index) in schools" :key="index" :value="school">{{ school }}</option>
      </select>
      <input id="school-class" class="primary-input" type="text" v-model="schoolClass" @input="validateSchoolClass"
        maxlength="16" :placeholder="translations[selectedLanguage].schoolClass" />
    </div>

    <!-- Boutons de sélection -->
    <div class="toggle-buttons">
      <button class="secondary-input" @click="toggleView('avatars')" :class="{ active: currentView === 'avatars' }">
        {{ translations[selectedLanguage].chooseAvatar }}
      </button>
      <button class="secondary-input" @click="toggleView('accessories')"
        :class="{ active: currentView === 'accessories' }">
        {{ translations[selectedLanguage].chooseAccessory }}
      </button>
    </div>

    <!-- Grilles dynamiques -->
    <div v-if="currentView === 'avatars'" class="avatar-grid">
      <div v-for="(avatar, index) in avatars" :key="index" class="avatar-cell" :class="`avatar-${index}`"
        @click="selectAvatar(index)"></div>
    </div>

    <div v-if="currentView === 'accessories'" class="accessory-grid">
      <div v-for="(accessory, index) in accessories" :key="index" class="accessory-cell" :class="`accessory-${index}`"
        @click="selectAccessory(index)"></div>
    </div>

    <label class="checkbox-container">
    <input type="checkbox" v-model="acceptTerms" />
    <span>{{ translations[selectedLanguage].terms }}</span>
  </label>

    <div class="game-controls">
      <NextButton :label="translations[selectedLanguage].validate" :enable="ready" @click="clickValidate" />
    </div>
  </div>


</template>

<script>
import AvatarDisplay from "@/components/AvatarDisplay.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
import schools from "@/data/schools.js";
import { mapState } from "vuex";
import defs from "@/service/definitions";
import NextButton from "@/components/NextButton.vue";

export default {
  components: {
    AvatarDisplay,
    LanguageSelector,
    NextButton
  },
  data() {
    return {
      currentView: "avatars", // Vue actuelle: avatars ou accessoires
      selectedAvatar: 0, // Avatar sélectionné par défaut
      selectedAccessory: 0, // Accessoire sélectionné par défaut
      avatars: Array.from({ length: 9 }), // Tableau pour les 9 avatars
      accessories: Array.from({ length: 9 }), // Tableau pour les 9 accessoires
      pseudo: "", // Pseudo du joueur
      schoolClass: "",
      selectedLanguage: "fr", // Langue par défaut
      selectedSchool: "", // École sélectionnée
      schools, // Liste des écoles
      acceptTerms: false,
      translations: {
        fr: {
          placeholder: "Pseudo (max 16 caractères)",
          schoolPlaceholder: "Choisis ton école",
          chooseAvatar: "Choisir un avatar",
          chooseAccessory: "Choisir un accessoire",
          validate: "Valider",
          terms: "J'ai pris connaissance et accepte les conditions de participation. Document disponible au bureau d'information.",
          schoolClass: "Classe",
        },
        de: {
          placeholder: "Benutzername (max. 16 Zeichen)",
          schoolPlaceholder: "Wähle deine Schule",
          chooseAvatar: "Wähle einen Avatar",
          chooseAccessory: "Wähle ein Zubehör",
          validate: "Bestätigen",
          terms: "Ich habe die Teilnahmebedingungen zur Kenntnis genommen und akzeptiere sie. Dokument im Informationsbüro erhältlich.",
          schoolClass: "Klasse",
        },
      },
    };
  },
  computed: {
    ...mapState({
      player: (state) => state.player,
    }),
    ready() {
      return (
        this.acceptTerms &&
        this.pseudo.trim() !== "" &&
        this.selectedSchool !== "" &&
        this.schoolClass.trim() !== ""
      );
    },
  },
  methods: {
    goToNext() {
      this.$router.push({ name: 'next' });
      this.$store.dispatch("stopLoading");
    },
    toggleView(view) {
      this.currentView = view;
    },
    selectAvatar(index) {
      this.selectedAvatar = index;
      console.log(`Avatar ${index} sélectionné`);
    },
    selectAccessory(index) {
      this.selectedAccessory = index;
      console.log(`Accessoire ${index} sélectionné`);
    },
    changeLanguage(lang) {
      this.selectedLanguage = lang;
    },
    validatePseudo() {
      this.pseudo = this.pseudo.replace(/[^ -~]/g, "");
    },
    async clickValidate() {
      this.$store.dispatch("startLoading");
      await this.player.startGame(this.selectedAvatar, this.selectedAccessory, this.selectedLanguage == 'de' ? defs.de : defs.fr, this.pseudo);
      this.goToNext();
    },
  },
};
</script>

<style scoped lang="scss">
/* Conteneur global */
.avatar-container {
  text-align: center;
  padding-top: 150px;
}

.avatar-display {
  width: 260px;
  aspect-ratio: 1;
}


$input-width: 50vw;

.checkbox-container {
  // padding: 1em;
  font-weight: normal;
}

.game-controls {
  padding: 1em;
}

/* Champ de pseudo */
.pseudo-container {
  margin: 1em 0;

  input {
    font-family: 'Funcom', sans-serif;
    font-size: x-large;
    font-weight: normal;
    text-align: center;
    width: $input-width;
    padding: 0;
  }

  input::placeholder {
    color: $primary-color;
  }
}


.language-selection {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
  gap: 0em;
}

.school-dropdown {
  select {
    width: 36vw;

    appearance: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE3IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMOC41IDguMjVMMTYgMC43NSIgc3Ryb2tlPSIjNzZDNkRBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: right 1.5em center;
    background-size: 16px 16px;
  }

  input {
    text-align: center;
    width: 12vw;
    margin-left: 1em;
    margin-top: 2px; // top align with select ???
    padding: 0;
  }

  input::placeholder {
    color: $primary-color;
  }
}

.toggle-buttons {
  padding-top: 3em;
}

/* Grilles */
.avatar-grid,
.accessory-grid {
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: repeat(3, 250px);
  gap: 10px;
  justify-content: center;
  justify-items: center;
}

.avatar-cell {
  background-image: url('@/assets/avatars.png');
}

.accessory-cell {
  background-image: url('@/assets/accessories.png');
}

.avatar-cell,
.accessory-cell {
  width: 230px;
  height: 230px;
  cursor: pointer;
  transition: border 0.3s ease;
  background-size: 690px 690px;
}

/* Positions des sprites avatars */
.avatar-0 {
  background-position: 0px 0px;
}

.avatar-1 {
  background-position: -230px 0px;
}

.avatar-2 {
  background-position: -460px 0px;
}

.avatar-3 {
  background-position: 0px -230px;
}

.avatar-4 {
  background-position: -230px -230px;
}

.avatar-5 {
  background-position: -460px -230px;
}

.avatar-6 {
  background-position: 0px -460px;
}

.avatar-7 {
  background-position: -230px -460px;
}

.avatar-8 {
  background-position: -460px -460px;
}

/* Positions des sprites accessoires */
.accessory-0 {
  background-position: 0px 0px;
}

.accessory-1 {
  background-position: -230px 0px;
}

.accessory-2 {
  background-position: -460px 0px;
}

.accessory-3 {
  background-position: 0px -230px;
}

.accessory-4 {
  background-position: -230px -230px;
}

.accessory-5 {
  background-position: -460px -230px;
}

.accessory-6 {
  background-position: 0px -460px;
}

.accessory-7 {
  background-position: -230px -460px;
}

.accessory-8 {
  background-position: -460px -460px;
}
</style>
