<template>
    <div class="amount-footer">
        <span>{{ title }} :&nbsp;</span>
        <CoinIcon />
        <span>&nbsp;</span>
        <InterpolatedValue :value="amount"/>
    </div>
</template>

<script>
import CoinIcon from "@/components/CoinIcon.vue";
import InterpolatedValue from "./InterpolatedValue.vue";

export default {
    name: "AmountField",
    components: {
        CoinIcon,
        InterpolatedValue,
    },
    props: {
        amount: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">

.amount-footer{
    background-color: $primary-color;
    border: solid 4px white;
    border-radius: 10px;
    box-sizing: border-box;
    color: white;
    font-size: 22pt;
    font-weight: normal;
    margin: auto;
    margin-top: 30px;
    padding: 0.5em 0;
    text-align: center;    
    width: $general-width;
}

</style>