<template>
    <div class="debug-header">
      <button v-if="isScreensaver" class="debug-button" @click="onDebugCardOn">Présenter la carte</button>
      <button v-else class="debug-button" @click="onCardOff">Retirer la carte</button>
  
      <div v-if="isScreensaver" class="debug-dropdown">
        <button class="debug-button" @click="togglePurchasesList">
          Stands
        </button>
        <div v-if="showPurchaseList" class="purchase-list">
          <div v-for="(purchase, code) in allPurchasesMap" :key="code" @click="onDebugPurchase(code)"
            class="purchase-item">
            <span>{{ code }} : {{ purchase["description-fr"] }} (${{ purchase.cost }})</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import defs from "@/service/definitions";
  import { simulateItem, getDebugCard } from "@/service/debugCard";
  
  export default {
    name: "DebugHeader",
    props: {
      isScreensaver: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        showPurchaseList: false,
      };
    },
    computed: {
      allPurchasesMap() {
        return defs.purchaseMap || {};
      },
    },
    methods: {
      onDebugCardOn() {
        this.$emit("debug-card-on", getDebugCard());
      },
      onCardOff() {
        this.$emit("debug-card-off");
      },
      onDebugPurchase(code) {
        const purchase = defs.purchaseMap[code];
        purchase.code = parseInt(code);
        const result = simulateItem(purchase);
        this.togglePurchasesList();
        this.$emit("debug-popup", result);
      },
      togglePurchasesList() {
        this.showPurchaseList = !this.showPurchaseList;
      },
    },
  };
  </script>
  
  <style scoped>
  .debug-header {
    position: fixed;
    top: 140px;
    left: 0;
    width: 30%;
    background-color: #f8d7da;
    padding: 10px;
    text-align: center;
    z-index: 1000;
  }
  
  .debug-button {
    background-color: red;
    color: white;
    padding: 0.5em;
    border: none;
    cursor: pointer;
    margin: 0.5em;
  }
  
  .debug-dropdown {
    display: inline-block;
    position: relative;
  }
  
  .purchase-list {
    position: absolute;
    top: 2.5em;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    padding: 0.5em;
    text-align: left;
    width: 25em;
  }
  
  .purchase-item {
    color: black;
    margin: 0.25em 0;
    cursor: pointer;
    flex-direction: row;
  }
  </style>
  