<template>
    <div class="card-instruction-container">
      <p class="card-instruction-text">
        {{ text }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: "CardInstruction",
    props: {
      text: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  
  .card-instruction-text {
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-align: center;
    animation: pulse 2s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
  }
  </style>
  