export default {
  game: {
    startAmount: 1500,
    lastRound: 10,
    minSalary: 300,
    salaryMap: {
      "cfc": 400,
      "maturity": 400,
      "certificate": 400,
      "license": 1500,
      "diploma": 1000,
      "bachelor": 1500,
      "master": 1000
    },
    insuredSuffixFr: " (assuré)",
    insuredSuffixDe: " (Versichert)",
    taxPercent: 20,
    maxLifeInsurances: 5,
    enableRisk: false
  },

  metaRoundAddress: 0x01,
  metaRoundRoundByte: 0,
  metaRoundLanguageByte: 1,
  metaRoundEducationByte: 2,
  metaRoundCertificatsByte: 3,
  metaRoundEventsFirstByte: 4,
  metaRoundInvestmentsByte: 14,
  metaRoundLifeInsuranceByte: 15,

  cfcMask: 0b00000001,
  maturityMask: 0b00000010,
  licenseMask: 0b00000100,
  diplomaMask: 0b00001000,
  bachelorMask: 0b00010000,
  masterMask: 0b00100000,
  cfcPlusMask: 0b01000000,
  maturityPlusMask: 0b10000000,

  investmentsMask: 0xE0,
  sellInvestmentsMask: 0xE8,
  pensionMask: 0xF0,
  lifeInsuranceMask: 0xA8,

  metaGameAddress: 0x11,
  pseudoAddress: 0x12,
  roundsFirstAddress: 0x04,

  fr: 0,
  de: 1,

  purchaseMap: {

    // Achats
    0x01: { id: "clothes", "description-fr": "Vêtements", "description-de": "Kleidung", cost: 100, points: 100, purchase: true }, 
    0x02: { id: "electronic", "description-fr": "Electronique", "description-de": "Elektronik", cost: 200, points: 200, purchase: true }, 
    0x03: { id: "furniture-appliances", "description-fr": "Mobilier et électroménager", "description-de": "Möbel und Geräte", cost: 500, points: 1000, purchase: true }, 
    0x04: { id: "valuables", "description-fr": "Biens précieux", "description-de": "Wertgegenstände", cost: 1000, points: 3000, purchase: true }, 

    0x09: { id: "hobbies", "description-fr": "Loisirs", "description-de": "Freizeit", cost: 100, points: 100, purchase: true }, 
    0x0A: { id: "travel", "description-fr": "Voyage", "description-de": "Reise", cost: 200, points: 400, purchase: true }, 
    0x0B: { id: "space-travel", "description-fr": "Voyage dans l'espace", "description-de": "Reise ins All", cost: 3000, points: 9000, purchase: true}, 

    0x11: { id: "scooter", "description-fr": "Scooter", "description-de": "Scooter", cost: 500, points: 500, purchase: true, mobility: true }, 
    0x12: { id: "car", "description-fr": "Voiture", "description-de": "Auto", cost: 1000, points: 2000, purchase: true, mobility: true }, 
    0x13: { id: "helicopter", "description-fr": "Hélicoptère", "description-de": "Hubschrauber", cost: 3000, points: 9000, purchase: true, mobility: true }, 

    0x19: { id: "apartment-with-mortgage", "description-fr": "Appartement avec hypothèque", "description-de": "Wohnung mit Hypothek", cost: 2000, points: 6000, purchase: true, property: true, "require-mortgage": true }, 
    0x1A: { id: "apartment", "description-fr": "Appartement", "description-de": "Wohnung", cost: 4000, points: 12000, purchase: true, property: true }, 
    0x1B: { id: "house-with-mortgage", "description-fr": "Maison avec hypothèque", "description-de": "Haus mit Hypothek", cost: 2500, points: 7500, purchase: true, property: true, "require-mortgage": true }, 
    0x1C: { id: "house", "description-fr": "Maison", "description-de": "Haus", cost: 5000, points: 15000, purchase: true, property: true  }, 

    // Formations
    0x41: { id: "cfc", "description-fr": "CFC", "description-de": "EFZ", cost: 0, points: 0, formation: true },
    0x42: { id: "maturity", "description-fr": "Maturité", "description-de": "Matura", cost: 0, points: 0, formation: true },
    0x49: { id: "certificate", "description-fr": "Certificat", "description-de": "Zertifikat", cost: 200, points: 200 , formation: true}, 
    0x51: { id: "license", "description-fr": "Brevet", "description-de": "Lizenz", cost: 1000, points: 2000 , formation: true}, 
    0x52: { id: "diploma", "description-fr": "Diplôme", "description-de": "Diplom", cost: 750, points: 2250 , formation: true}, 
    0x59: { id: "bachelor", "description-fr": "Bachelor", "description-de": "Bachelor", cost: 1000, points: 2000 , formation: true}, 
    0x5A: { id: "master", "description-fr": "Master", "description-de": "Master", cost: 750, points: 2250 , formation: true}, 

    // Assurances
    0x80: { id: "insured-good", "description-fr": "Assurance biens", "description-de": "Sachversicherung", percentCost: 10, points: 0, insurance: true },
    0x81: { id: "insured-social", "description-fr": "Assurance hobbies", "description-de": "Freizeitversicherung", percentCost: 10, points: 0, insurance: true },
    0x82: { id: "insured-mobility", "description-fr": "Assurance mobilité", "description-de": "Mobilitätsversicherung", percentCost: 10, points: 0, insurance: true },
    0x83: { id: "insured-house", "description-fr": "Assurance immobilier", "description-de": "Hausversicherung", percentCost: 10, points: 0, insurance: true },
    
    0xA1: { id: "additional-health-insurance", "description-fr": "Assurance maladie complémentaire", "description-de": "Zusatzkrankenversicherung", cost: 50, points: 50}, 
    0xA8: { id: "life-insurance", "description-fr": "Assurance vie", "description-de": "Lebensversicherung", cost: 100, points: 300}, 

    // Banques
    0xD8: { id: "mortgage-apartment", "description-fr": "Hypothèque pour appartement", "description-de": "Wohnungshypothek", cost: 0, points: 0, bank: true, mortgage: 200},
    0xD9: { id: "mortgage-house", "description-fr": "Hypothèque pour maison", "description-de": "Haushypothek", cost: 0, points: 0, bank: true, mortgage: 250},

    0xE0: { id: "investments", "description-fr": "Placements financiers", "description-de": "Finanzinvestitionen", cost: 100, points: 200 , bank: true}, 
    0xE8: { id: "investments-sell", "description-fr": "Vendre actions", "description-de": "Aktien verkaufen", cost: -100, points: 200 , bank: true}, 
    0xF0: { id: "pension-savings", "description-fr": "Epargne retraite", "description-de": "Altersvorsorge", cost: 100, points: 300 , bank: true}, 
  },

  risks: {
    0x04: {
      id: "backpain", excludedBy: "additional-health-insurance", amount: -100,
      "title-fr": "Ça craint !", "desc-fr": "Vous avez mal au dos et optez pour la médecine chinoise. Vous allez mieux."
    },
    0x05: {
      id: "backpain", target: "additional-health-insurance", amount: 0,
      "title-fr": "Ça craint... ou pas.", "desc-fr": "Vous avez mal au dos et optez pour la médecine chinoise. Vous allez mieux et omme vous êtes assuré, on vous rembourse."
    },
    0x13: {
      id: "doc", amount: 500,
      "title-fr": "Ça régale !", "desc-fr": "Vous vendez un documentaire sur les meilleurs pistes de ski du Valais."
    },
    0x14: {
      id: "chat-website", amount: 300,
      "title-fr": "Ça régale !", "desc-fr": "Vous investissez dans le site web d'un ami consacré au dressage de chat, le site cartonne."
    },
    0x15: {
      id: "world-conferences", amount: 500,
      "title-fr": "Ça régale !", "desc-fr": "Votre parcours de vie suscite l'intérêt ! On vous réclame dans le monde entier pour donner des conférences."
    },
    0x16: {
      id: "reality-show", amount: 300,
      "title-fr": "Ça régale !", "desc-fr": "Vous êtes, avec vos proches, les héros d'une téléréalité."
    },
    0x17: {
      id: "book-success", amount: 300,
      "title-fr": "Ça régale !", "desc-fr": "Vous écrivez un livre et il est traduit en anglais. Vous triplez les ventes."
    },
    0x18: {
      id: "jass-winner", amount: 100,
      "title-fr": "Ça régale !", "desc-fr": "Vous gagnez le concours de jass annuel de votre entreprise."
    },
    0x19: {
      id: "parents-business", amount: 500,
      "title-fr": "Ça régale !", "desc-fr": "Vous reprenez l'entreprise de vos parents et elle se porte bien."
    },
    0x1A: {
      id: "grape-juice", amount: 300,
      "title-fr": "Ça régale !", "desc-fr": "Vous lancez un nouveau jus de raisin grâce aux vignes de votre famille, il cartonne."
    },
    0x1B: {
      id: "business-bonus", amount: 200,
      "title-fr": "Ça régale !", "desc-fr": "Bonne année pour votre entreprise, vous touchez un bonus !"
    },

    // Biens précieux
    0x20: {
      id: "valuable-theft", target: "valuable", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "On vous a volé votre bien le plus précieux."
    },
    0x21: {
      id: "valuable-theft", target: "insured-valuable", amount: 1000, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "On vous a volé votre bien le plus précieux, mais heureusement, l'assurance le couvre !"
    },

    // Mobilier
    0x22: {
      id: "furniture-damage", target: "furniture-appliances", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "Votre chat fait tomber votre TV."
    },
    0x23: {
      id: "furniture-damage", target: "insured-furniture-appliances", amount: 500, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "Votre chat fait tomber votre TV, mais l'assurance la rembourse."
    },

    // Vêtements
    0x24: {
      id: "clothes-lost", target: "clothes", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "Vous perdez votre valise à l'aéroport de Paris."
    },
    0x25: {
      id: "clothes-lost", target: "insured-clothes", amount: 100, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "Vous perdez votre valise, mais votre assurance vous rembourse !"
    },

    // Electronique
    0x26: {
      id: "laptop-stolen", target: "electronic", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "Vous vous faites voler votre ordinateur portable."
    },
    0x27: {
      id: "laptop-stolen", target: "insured-electronic", amount: 200, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "On vous vole votre ordinateur, mais heureusement, vous êtes assuré."
    },

    // Voyage dans l'espace
    0x28: {
      id: "space-travel-cancelled", target: "space-travel", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "Votre voyage dans l'espace est annulé à cause d'un défaut de la fusée."
    },
    0x29: {
      id: "space-travel-cancelled", target: "insured-space-travel", amount: 4000, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "Votre voyage est annulé, mais votre assurance couvre les frais."
    },

    // Annulation de voyage
    0x2A: {
      id: "last-minute-cancel", target: "travel", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "Vous devez annuler votre voyage à la dernière minute."
    },
    0x2B: {
      id: "last-minute-cancel", target: "insured-travel", amount: 300, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "Vous annulez votre voyage, mais l'assurance couvre les frais."
    },

    // Loisirs
    0x2C: {
      id: "bike-damage", target: "hobbies", amount: 0, delete: true,
      "title-fr": "Ça craint !", "desc-fr": "Vous cassez votre VTT lors d'une balade en forêt."
    },
    0x2D: {
      id: "bike-damage", target: "insured-hobbies", amount: 100, delete: true,
      "title-fr": "Ça craint... ou pas !", "desc-fr": "Votre VTT est cassé, mais l'assurance vous le rembourse."
    }
  },
};
