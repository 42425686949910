<template>
  <div class="progress-level-container">
    <div class="progress-container">
      <div class="progress">
        <div class="progress-inner" :style="'width:' + percentage"></div>
      </div>
      <div class="points">
        {{ displayValue }} pts
      </div>
    </div>
    <div class="level">
      <div class="number">
        {{ level }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentValue: this.value,
      displayValue: this.value,
      animationFrame: null,
      levelMap: [
        0, 600, 1400, 2400, 3600, 5000, 6600, 8400, 10400, 12600,
        15000, 17600, 20400, 23400, 26600, 30000, 33600, 37400, 41400, 45600,
        50000, 54600, 59400, 64400, 69600, 75000, 80600, 86400, 92400, 98600,
        105000, 111600, 118400, 125400, 132600, 140000, 147600, 155400, 163400, 171600,
        180000, 188600, 197400
      ]
    };
  },
  computed: {
    level(){

      for( let lvl = 1; lvl < this.levelMap.length; lvl++ ){

        if( this.levelMap[lvl] >= this.displayValue ){
          return lvl;
        }
      }
      return  this.levelMap.length-1;
    },
    percentage() {
      const percentage = ((this.displayValue - this.levelMap[this.level - 1]) / (this.levelMap[this.level] - this.levelMap[this.level - 1])) * 80 + 20;
      return `${Math.min(100, Math.max(0, percentage.toFixed(2)))}%`;
    },
  },
  watch: {
    value(newValue) {
      if (this.currentValue != null && newValue != null) {
        this.animateValue(newValue);
      }else{
        this.displayValue = newValue;
        this.currentValue = newValue;
      }
    }
  },
  methods: {
    /**
     * Fonction d'easing : Ralentissement exponentiel (ease-out)
     * @param {number} t - Progression de 0 à 1
     * @returns {number} - Progression ajustée
     */
    easeOutExpo(t) {
      return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
    },
    animateValue(newValue) {
      const duration = 3000; // Durée de l'animation en millisecondes
      const startValue = this.currentValue;
      const difference = newValue - startValue;
      const startTime = performance.now();

      const step = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // Progression entre 0 et 1
        const easedProgress = this.easeOutExpo(progress); // Applique l'easing
        this.displayValue = Math.round(startValue + difference * easedProgress);

        if (progress < 1) {
          this.animationFrame = requestAnimationFrame(step);
        } else {
          this.currentValue = newValue; // Animation terminée
        }
      };

      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }

      this.animationFrame = requestAnimationFrame(step);
    },
  },
  mounted() {

  },
  beforeUnmount() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }
};
</script>

<style scoped lang="scss">
$progress-height: 20px;
$level-height: 3 * $progress-height;
$progress-border: 4px;

.progress {

  width: 30vw;
  border-radius: $progress-height;
  border: solid $progress-border white;
  background-color: white;

  .progress-inner {
    background-color: $secondary-color;
    border-radius: $progress-height;
    height: $progress-height;
  }
}

.progress-level-container {
  
  height: $level-height;
}

.progress-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.points {
  background-color: white;
  border: solid 2px white;
  border-radius: 20px;
  color: #82368c;
  font-family: "Funcom", sans-serif;
  font-size: 15pt;
  margin: 12px;
  padding-top: 0.2em;
  width: 12vw;
}

.level {

  align-content: center;
  top: - $level-height  + $progress-border;
  position: relative;
  height: $level-height;
  width: $level-height;
  color: $secondary-color;
  background-color: white;
  border-radius: $level-height;
  font-family: "Funcom", sans-serif;
  font-size: xx-large;
  font-weight: normal;
}
</style>