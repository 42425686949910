import defs from "./definitions";
import store from '@/store';
import { debugCard, addressMap } from "./debugCard";

async function write_data(data) {

  if (store.state.debugMode) {
    console.log("DEBUG MODE: write_data called with", data);

    var index = addressMap.indexOf(data[0])

    debugCard[index] = data;

    return true;
  }

  console.log("write_data : ", data);
  try {
    const response = await fetch("http://localhost/write_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bytes: data }),
    });

    const result = await response.json();
    console.log("Response from server:", result);
    return result.status === "ok";
  } catch (error) {
    console.error("Error sending data:", error);
    return false;
  }
}

async function write_cleanRound(amount, roundIndex, purchaseCodes) {
  console.log("write_cleanRound ", amount, roundIndex);

  const data = [
    addressFromRound(roundIndex), (amount >> 8) & 0xff, amount & 0xff, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  let addr = 3;
  for (var code of purchaseCodes) {
    data[addr] = code;
    addr++;
  }

  return await write_data(data);
}

async function write_round_meta(roundIndex, languageId, formations = 0, nCertificats = 0, events = [], investments = 0, lifeInsurance = 0) {
  console.log("write_round_meta ", roundIndex, languageId, formations, nCertificats, events);

  const data = [
    defs.metaRoundAddress,
    roundIndex, languageId, formations, nCertificats,
    // risks are set bellow 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    investments,
    lifeInsurance,
  ];

  var index = defs.metaRoundEventsFirstByte + 1;

  for (let i in events) {
    let event = events[i];
    data[index] = event.code;
    index++;
  }

  return await write_data(data);
}

async function write_game_meta(avatarID, accessoryID) {
  console.log("write_game_meta ", avatarID, accessoryID);

  const data = [
    defs.metaGameAddress, avatarID, accessoryID, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  return await write_data(data);
}

async function write_pseudo(pseudo) {

  const data = [
    defs.pseudoAddress, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  console.log("write pseudo :");

  for (let i = 0; i < pseudo.length && i < data.length - 1; i++) {
    data[i + 1] = pseudo.charCodeAt(i);
    console.log(pseudo.charCodeAt(i));
  }

  return await write_data(data);
}

/**
* Calcule le numéro de round à partir de l'adresse.
* @param {number} address - L'adresse à convertir en numéro de round.
* @returns {number} Le numéro de round.
*/
function roundFromAddress(address) {
  return (
    address -
    defs.roundsFirstAddress -
    Math.floor((address - defs.roundsFirstAddress) / 4) +
    1
  );
}

export function addressFromRound(round) {
  return (
    defs.roundsFirstAddress +
    round +
    Math.floor((round - 1) / 3) -
    1
  );
}

export async function resetGame() {
  console.log("resetGame");
  return await write_round_meta(0, 0, 0, 0);
}

export async function startRound(amount, roundIndex, languageId, formations, nCertificats, events, purchases, investments, lifeInsurance) {

  console.log("startRound", amount, roundIndex, languageId, formations, nCertificats, events, purchases, investments, lifeInsurance);

  let purchaseCodes = [];

  for (let purchase of purchases) {
    purchaseCodes.push(purchase.code);
  }

  let success = await write_cleanRound(amount, roundIndex, purchaseCodes)

  if (success) {
    success = await write_round_meta(roundIndex, languageId, formations, nCertificats, events, investments, lifeInsurance);
  }

  return success;
}

export async function startGame(avatarID, accessoryID, languageId, pseudo, amount) {

  console.log("startGame", avatarID, accessoryID, languageId, pseudo, amount);
  let success = await write_game_meta(avatarID, accessoryID);

  if (success) {
    success = await write_pseudo(pseudo);
  }

  if (success) {
    success = await startRound(amount, 1, languageId, 0, 0, [], []);
  }
}

/**
  * Analyse les données brutes pour extraire les informations sur les rounds.
  * @param {string} rawData - Les données brutes sous forme de chaîne hexadécimale.
  * @returns {Object} Un objet contenant le round actuel et les détails des rounds.
  */
export function readCard(rawData) {
  const dataLength = rawData.length;
  const blockSize = 34; // Chaque bloc de 17 bytes correspond à 34 caractères en hexadécimal (2 caractères par byte)
  const rounds = {};
  let currentRound = 0;
  let avatarId = -1;
  let accessoryId = -1;
  let languageId = -1;
  let education = 0;
  let certificates = -1;
  let riskEvents = [];

  let pseudo = "";

  for (let i = 0; i < dataLength; i += blockSize) {
    // Extraire un bloc de 34 caractères (17 bytes) et convertir en valeurs
    const block = rawData.slice(i, i + blockSize);
    const address = parseInt(block.slice(0, 2), 16);

    console.log("Parse " + block);

    if (address === defs.metaRoundAddress) {

      currentRound = parseInt(block.slice(2, 4), 16);
      languageId = parseInt(block.slice(4, 6), 16);
      education = parseInt(block.slice(6, 8), 16);
      certificates = parseInt(block.slice(8, 10), 16);

      for (let j = 10; j < 30; j += 2) {
        const riskCode = parseInt(block.slice(j, j + 2), 16);

        if (riskCode !== 0) {

          let risk = defs.risks[riskCode];

          risk.code = riskCode;

          riskEvents.push(risk);
        }
      }

      console.log("->metaRound : ", currentRound, ", ", languageId, ", ", riskEvents);

    } else if (address === defs.metaGameAddress) {

      avatarId = parseInt(block.slice(2, 4), 16);
      accessoryId = parseInt(block.slice(4, 6), 16);

      console.log("->metaGame");

    } else if (address === defs.pseudoAddress) {

      console.log("->pseudo");

      for (let j = 2; j < blockSize; j += 2) {
        const charCode = parseInt(block.slice(j, j + 2), 16);

        if (charCode === 0) {
          break;
        }

        pseudo += String.fromCharCode(charCode);
      }

    } else if (address >= defs.roundsFirstAddress) {

      const round = roundFromAddress(address);
      const purchases = [];
      let investments = 0;
      let pension = 0;
      let lifeInsurance = 0;

      console.log("->round ", round);

      if (round <= currentRound) {

        for (let j = 6; j < blockSize; j += 2) {
          const purchaseCode = parseInt(block.slice(j, j + 2), 16);

          let purchase;
          let qty;

          if ((purchaseCode & 0b11111000) === defs.investmentsMask) {

            qty = purchaseCode & 0b111;
            investments += qty;

            purchase = defs.purchaseMap[defs.investmentsMask];
            purchase.code = defs.investmentsMask;
            
            for( let i = 0; i < qty; i++){
              purchases.push(purchase);
            }

          }else if ((purchaseCode & 0b11111000) === defs.sellInvestmentsMask) {

            qty = purchaseCode & 0b111;
            investments -= qty;

            purchase = defs.purchaseMap[defs.sellInvestmentsMask];
            purchase.code = defs.sellInvestmentsMask;
            
            for( let i = 0; i < qty; i++){
              purchases.push(purchase);
            }

          }else if ((purchaseCode & 0b11111000) === defs.pensionMask) {

            qty = purchaseCode & 0b111;
            pension += qty;

            purchase = defs.purchaseMap[defs.pensionMask];
            purchase.code = defs.pensionMask;
            
            for( let i = 0; i < qty; i++){
              purchases.push(purchase);
            }

          }else if ((purchaseCode & 0b11111000) === defs.lifeInsuranceMask) {

            qty = purchaseCode & 0b111;
            lifeInsurance += qty;

            purchase = defs.purchaseMap[defs.lifeInsuranceMask];
            purchase.code = defs.lifeInsuranceMask;
            
            for( let i = 0; i < qty; i++){
              purchases.push(purchase);
            }

          } else if (purchaseCode !== 0) {

            if ((purchaseCode & 0b11100000) === 0b10000000) {

              // Insured
              let refPurchase = defs.purchaseMap[purchaseCode & 0b01111111];

              purchase = {
                id: "insured-" + refPurchase.id,
                "description-fr": refPurchase["description-fr"] + defs.game.insuredSuffixFr,
                "description-de": refPurchase["description-de"] + defs.game.insuredSuffixDe,
                "require-mortgage": refPurchase["require-mortgage"] || false,
                property: refPurchase.property || false,
                insured: true,
                cost: parseInt(refPurchase.cost * 1.1),
                points: parseInt(refPurchase.points * 1.1),
              }

            } else {
              purchase = defs.purchaseMap[purchaseCode];
            }

            purchase.code = purchaseCode;
            purchases.push(purchase);
          }
        }

        rounds[round] = {
          round: round,
          amount: parseInt(block.slice(2, 6), 16),
          purchases: purchases,
          investments: investments,
          pension: pension,
          lifeInsurance: lifeInsurance,
        }
      }
    }
  }

  return {
    avatarId: avatarId,
    accessoryId: accessoryId,
    certificates: certificates,
    currentRound: currentRound,
    education: education,
    events: riskEvents,
    languageId: languageId,
    pseudo: pseudo,
    rounds: rounds,
    raw: rawData,
  };
}
