<template>

    <AmountField 
    :title="translations[selectedLanguage].available"
    :amount="availableAmount"/>

    <div class="detail">
        <span>
            {{ translations[selectedLanguage].details }} (<CoinIcon />&nbsp;</span>
            <InterpolatedValue :value="grandAmount" /><span>) - 
            {{ translations[selectedLanguage].charges }} (<CoinIcon />&nbsp;{{ outcomes }}) - 
            {{ translations[selectedLanguage].taxes }} (<CoinIcon />&nbsp;{{ taxes }})
        </span>
    </div>
</template>

<script>
import CoinIcon from "@/components/CoinIcon.vue";
import InterpolatedValue from "./InterpolatedValue.vue";
import AmountField from "./AmountField.vue";

export default {
    name: "AmountFooter",
    components: {
        AmountField,
        CoinIcon,
        InterpolatedValue,
    },
    props: {
        grandAmount: {
            type: Number,
            required: true,
        },
        outcomes: {
            type: Number,
            required: true,
        },
        taxes: {
            type: Number,
            required: true,
        },
        selectedLanguage: {
            type: String,
            required: true,
        },
    },
    computed: {
        availableAmount(){
            return this.grandAmount - this.outcomes - this.taxes;
        }
    },
    data() {
        return {
            translations: {
                fr: {
                    available: "Capital disponible",
                    details: "Capital disponible = capital",
                    charges: "charges",
                    taxes: "impôts",
                },
                de: {
                    available: "Verfügbares Kapital",
                    details: "Verfügbares Kapital = Kapital",
                    charges: "Ausgaben",
                    taxes: "Steuern",
                },
            },
        };
    },
};
</script>

<style scoped lang="scss">

.detail{
    margin-top: 0.25em;
}

</style>