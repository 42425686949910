/* eslint-disable */

import defs from "./definitions";
import debugCardHex from "./debugCardData";
import {addressFromRound} from "./card";

export const addressMap = [0x01, 0x02, 0x04, 0x05, 0x06, 0x08, 0x09, 0x0A, 0x0C, 0x0D, 0x0E, 0x10, 0x11, 0x12];

export var debugCard = Array.from({ length: addressMap.length }, () => Array(17).fill(0));

if (debugCardHex) {
    debugCard = debugCardHex.map(row =>
        Array.from(row.match(/../g), byte => parseInt(byte, 16))
    );
}

export function getDebugCard() {
    let result = "";
    for (let i = 0; i < debugCard.length; i++) {
      for (let j = 0; j < debugCard[i].length; j++) {
        // Convertir la valeur en hexadécimal sur 2 caractères
        let hex = debugCard[i][j].toString(16).toUpperCase().padStart(2, '0');
        result += hex;
      }
    }
    return result;
  }

function simulateEducation(item, roundIndex, amount) {

    var result = "Problème technique";

    if (item.cost > amount) {
        result = "Impossible (solde insuffisant)";
    } else {

        const educationByte = debugCard[addressMap.indexOf(defs.metaRoundAddress)][defs.metaRoundEducationByte + 1];
        let dependenciesOk = true;

        switch (item.id) {

            case "diploma":
                dependenciesOk &= ((educationByte & defs.diplomaMask) == 0)
                dependenciesOk &= ((educationByte & defs.licenseMask) != 0)
                break;

            case "master":
                dependenciesOk &= ((educationByte & defs.masterMask) == 0)
                dependenciesOk &= ((educationByte & defs.bachelorMask) != 0)
                break;

            case "bachelor":
                dependenciesOk &= ((educationByte & defs.bachelorMask) == 0)
                dependenciesOk &= ((educationByte & defs.licenseMask) == 0)
                dependenciesOk &= (((educationByte & defs.cfcMask) | (educationByte & defs.maturityMask)) != 0)
                break;

            case "license":
                dependenciesOk &= ((educationByte & defs.bachelorMask) == 0)
                dependenciesOk &= ((educationByte & defs.licenseMask) == 0)
                dependenciesOk &= (((educationByte & defs.cfcPlusMask) | (educationByte & defs.maturityPlusMask)) != 0)
                break;

            case "certificate":
                dependenciesOk &= (((educationByte & defs.cfcMask) | (educationByte & defs.maturityMask)) != 0)
                break;

            case "maturity":
                dependenciesOk &= ((educationByte & defs.cfcMask) == 0)
                dependenciesOk &= ((educationByte & defs.maturityMask) == 0)
                break

            case "cfc":
                dependenciesOk &= ((educationByte & defs.cfcMask) == 0)
                dependenciesOk &= ((educationByte & defs.maturityMask) == 0)
                break
            default:
                // no nothing
                break
        }

        if (!dependenciesOk) {
            result = "Impossible (dépendence)";
        } else {

            for (let i = 3; i < 17; i++) {
                if (debugCard[roundIndex][i] == 0) {

                    debugCard[roundIndex][i] = item.code;
                    amount -= item.cost;

                    debugCard[roundIndex][1] = amount >> 8;
                    debugCard[roundIndex][2] = amount % 256;
                    result = "Ok";
                    break

                } else if ((debugCard[roundIndex][i] & 0xC0) === 0x40) {
                    // Byte 01xx xxxx => education

                    result = "Impossible (déjà une formation en cours)";
                    break;
                }
            }
        }
    }

    return result;
}

function simulateInsurance(purchase, roundIndex, amount) {

    var result = "Problème technique";
    var category = 0x00;

    if (purchase.id === "insured-good") {
        category = 0x00;
    } else if (purchase.id === "insured-social") {
        category = 0x08;
    } else if (purchase.id === "insured-mobility") {
        category = 0x10;
    } else if (purchase.id === "insured-house") {
        category = 0x18;
    } else {
        return result;
    }

    result = "Impossible (achat précédent non assurable)";
    for (let i = 16; i >= 3; i--) {

        if (debugCard[roundIndex][i] != 0) {

            const lastPurchaseCode = debugCard[roundIndex][i];

            // console.log('lastPurchaseCode', lastPurchaseCode);

            if ((lastPurchaseCode & 0b11111000) === category) {

                // Insuranceable

                const lastPurchase = defs.purchaseMap[lastPurchaseCode];

                let cost = parseInt(lastPurchase.cost * purchase.percentCost / 100);

                console.log("cost", cost);
                console.log('amou', amount);

                if (cost > amount) {
                    result = "Impossible (solde insuffisant)"

                } else {

                    amount -= cost;

                    debugCard[roundIndex][i] = lastPurchaseCode | 0b10000000;
                    debugCard[roundIndex][1] = amount >> 8;
                    debugCard[roundIndex][2] = amount % 256;

                    result = "Ok (" + defs.purchaseMap[lastPurchaseCode]["description-fr"] + " assuré)";
                }

                break;
            }
        }
    }

    return result;
}

function simulatePurchase(purchase, roundIndex, amount) {

    var result = "Problème technique";

    console.log(amount);
    console.log(purchase);

    if (purchase.cost > amount) {
        result = "Impossible (solde insuffisant)";
    } else {
        for (let i = 3; i < 17; i++) {
            if (debugCard[roundIndex][i] == 0) {

                debugCard[roundIndex][i] = purchase.code;
                amount -= purchase.cost;

                debugCard[roundIndex][1] = amount >> 8;
                debugCard[roundIndex][2] = amount % 256;
                result = "Ok";
                break
            }
        }
    }

    return result;
}

function simulateBuyInvestement(purchase, roundIndex, amount ) {
    var result = "Problème technique";

    if (purchase.cost > amount) {
        result = "Impossible (solde insuffisant)";
    } else {
        for (let i = 3; i < 17; i++) {
            let nInvestments = 0;

            if( ( debugCard[roundIndex][i] & 0b11111000 ) == defs.investmentsMask ){
                nInvestments = debugCard[roundIndex][i] & 0b00000111;

                if( nInvestments < 7 ){
                    nInvestments++;
                }else{
                    continue;
                }
                
            } else if (debugCard[roundIndex][i] == 0) {
                nInvestments = 1;
            }

            if( nInvestments > 0 ){
                debugCard[roundIndex][i] = defs.investmentsMask + nInvestments;
                amount -= purchase.cost;

                debugCard[roundIndex][1] = amount >> 8;
                debugCard[roundIndex][2] = amount % 256;
                result = "Ok";
                break
            }
        }
    }

    return result;
}

function simulatePension(purchase, roundIndex, amount ) {
    var result = "Problème technique";

    if (purchase.cost > amount) {
        result = "Impossible (solde insuffisant)";
    } else {
        for (let i = 3; i < 17; i++) {
            let nPension = 0;

            if( ( debugCard[roundIndex][i] & 0b11111000 ) == defs.pensionMask ){
                nPension = debugCard[roundIndex][i] & 0b00000111;

                if( nPension < 5 ){
                    nPension++;
                }else{
                    result = "Impossible (max atteint ce tour)";
                    break;
                }
                
            } else if (debugCard[roundIndex][i] == 0) {
                nPension = 1;
            }

            if( nPension > 0 ){
                debugCard[roundIndex][i] = defs.pensionMask + nPension;
                amount -= purchase.cost;

                debugCard[roundIndex][1] = amount >> 8;
                debugCard[roundIndex][2] = amount % 256;
                result = "Ok";
                break;
            }
        }
    }

    return result;
}

function simulateLifeInsurance(purchase, roundIndex, amount ) {
    var result = "Problème technique";

    let totalLifeInsurance = debugCard[addressMap.indexOf(defs.metaRoundAddress)][defs.metaRoundLifeInsuranceByte + 1];

    if (purchase.cost > amount) {
        result = "Impossible (solde insuffisant)";
    } else if( totalLifeInsurance >= defs.game.maxLifeInsurances){
        result = "Impossible (max atteint)";
    } else {
        for (let i = 3; i < 17; i++) {
            let n = 0;

            if( ( debugCard[roundIndex][i] & 0b11111000 ) == defs.lifeInsuranceMask ){
                n = debugCard[roundIndex][i] & 0b00000111;

                if( n + totalLifeInsurance < defs.game.maxLifeInsurances ){
                    n++;
                }else{
                    result = "Impossible (max atteint)";
                    break;
                }
                
            } else if (debugCard[roundIndex][i] == 0) {
                n = 1;
            }

            if( n > 0 ){
                debugCard[roundIndex][i] = defs.lifeInsuranceMask + n;
                amount -= purchase.cost;

                debugCard[roundIndex][1] = amount >> 8;
                debugCard[roundIndex][2] = amount % 256;
                result = "Ok";
                break;
            }
        }
    }

    return result;
}

function simulateSellInvestement(purchase, roundIndex, amount ) {
    var result = "Impossible (pas d'action à vendre)";
    
    let totalInvestments = debugCard[addressMap.indexOf(defs.metaRoundAddress)][defs.metaRoundInvestmentsByte + 1];
    
    for (let i = 3; i < 17; i++) {
        let nInvestments = 0;

        if( ( debugCard[roundIndex][i] & 0b11111000 ) == defs.sellInvestmentsMask ){
            nInvestments = debugCard[roundIndex][i] & 0b00000111;

            totalInvestments -= nInvestments;

            if( totalInvestments < 1 ){

                break;

            }else if( nInvestments < 7 ){

                nInvestments++;

            }else{

                continue;
            }
            
        } else if (debugCard[roundIndex][i] == 0) {

            if( totalInvestments < 1 ){

                break;

            }else{

                nInvestments = 1;
            }
        }

        if( nInvestments > 0 ){
            debugCard[roundIndex][i] = defs.sellInvestmentsMask + nInvestments;
            amount += purchase.cost;

            debugCard[roundIndex][1] = amount >> 8;
            debugCard[roundIndex][2] = amount % 256;
            result = "Ok";
            break
        }
    }
    

    return result;
}

function simulateMortgagePurchase(purchase, roundIndex, amount) {

    var result = "Problème technique";
    var mortgageCode = 0xFF;

    if (purchase.id === "apartment-with-mortgage") {
        mortgageCode = 0xD8;
    } else if (purchase.id === "house-with-mortgage") {
        mortgageCode = 0xD9;
    }

    result = "Impossible (pas d'hypothèque correspondante)";

    for (let i = 16; i >= 3; i--) {

        if (debugCard[roundIndex][i] != 0) {

            const lastPurchaseCode = debugCard[roundIndex][i];

            if ( lastPurchaseCode == mortgageCode ) {

                // Insuranceable

                let cost = purchase.cost;

                console.log("cost", cost);
                console.log('amou', amount);

                if (cost > amount) {
                    result = "Impossible (solde insuffisant)"

                } else {

                    amount -= cost;

                    debugCard[roundIndex][i] = purchase.code;
                    debugCard[roundIndex][1] = amount >> 8;
                    debugCard[roundIndex][2] = amount % 256;

                    result = "Ok";
                }

                break;
            }
        }
    }

    return result;
}

export function simulateItem(item) {

    var result = "Problème technique";

    const round = debugCard[addressMap.indexOf(defs.metaRoundAddress)][1];

    console.log("Round", round);

    if (round == 0) {
        result = "Impossible (jeu non démarré)"
    } else if (round <= defs.game.lastRound) {

        const roundIndex = addressMap.indexOf(addressFromRound(round));

        var amount = (debugCard[roundIndex][1] << 8) + debugCard[roundIndex][2];

        if (item.formation) {
            result = simulateEducation(item, roundIndex, amount);
        } else if (item.insurance) {
            result = simulateInsurance(item, roundIndex, amount);
        } else if (item["require-mortgage"]) {
            result = simulateMortgagePurchase(item, roundIndex, amount);
        } else if (item.id == 'investments') {
            result = simulateBuyInvestement(item, roundIndex, amount);
        } else if (item.id == 'investments-sell') {
            result = simulateSellInvestement(item, roundIndex, amount);
        } else if (item.id == 'pension-savings') {
            result = simulatePension(item, roundIndex, amount);
        } else if (item.id == 'life-insurance') {
            result = simulateLifeInsurance(item, roundIndex, amount);
        } else {
            result = simulatePurchase(item, roundIndex, amount);
        }
    }

    const debugCardString = getDebugCard();

    let formattedString = "";
    for (let i = 0; i < debugCardString.length; i += 34) {
        formattedString += debugCardString.slice(i, i + 34) + "\n";
    }

    console.log(formattedString);

    return result;
}
