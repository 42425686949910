<template>
  <button class="primary-input" :disabled="!enable" @click="onClick">
    <span>
      {{ label }}&nbsp;
    </span>
    <img :src="icon" alt="icon" />
  </button>
</template>

<script>
export default {
  name: "NextButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: () => require('@/assets/right-arrow.svg'),
    },
    enable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>