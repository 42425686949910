<template> 
<div class="container" >
  <div class="content">
    <img src="@/assets/logo.svg" class="logo"/>
    <img src="@/assets/rfid.png" class="rfid gelatine"/>
  </div>
  <div class="footer">
    Made by Des Choses Pareilles - Version {{ $store.state.packageVersion }}
  </div>
</div>
</template>

<script>
export default {
};
</script>



<style lang="scss" scoped>
.container {

}

p {
  font-size: 4em;
}

.rfid {
  width: 250px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 25vh;
}

.logo {
  width: 935px;
  transform: rotate(-5deg); 
  margin: 0 auto;
  display: block;
  position: relative;
  top: -2vh;
}

.shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.gelatine {
animation: gelatine 2.5s infinite;
}
@keyframes gelatine {
from, to { transform: scale(1, 1); }
80% { transform: scale(1, 1); }
85% { transform: scale(0.9, 1.1); }
90% { transform: scale(1.1, 0.9); }
95% { transform: scale(0.95, 1.05); }
}

</style>

