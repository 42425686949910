<template>
    <div class="purchase-item" :class="possession.qty > 0 ? '' : 'disabled' ">
        <img :src="require(`@/assets/purchases/${item.id}.png`)" :alt="item[`description-${selectedLanguage}`]"
            class="purchase-image" />
        <img v-if="possession.insuredQty" :src="require(`@/assets/purchases/insured.png`)" :alt="insured"
            class="insured-label" />
        <span>{{ possession.qty }} x {{ item[`description-${selectedLanguage}`] }} </span>
        <span v-if="possession.insuredQty > 0 && possession.insuredQty == possession.qty">{{ translations[selectedLanguage].insured }}</span>
        <span v-else-if="possession.insuredQty > 1">{{ translations[selectedLanguage].insuredMore.replace("{qty}", possession.insuredQty) }}</span>
        <span v-else-if="possession.insuredQty > 0">{{ translations[selectedLanguage].insuredOne }}</span>
    </div>
</template>

<script>
export default {
    name: "PurchaseItem",
    props: {
        item: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedLanguage: {
            type: String,
            required: true, // 'fr' ou 'de'
        },
        possession: {
            type: Object,
            required: true,
            default () {
                return {
                    qty: 0
                }
            }
        }
    },
    data() {
        return {
            translations: {
                fr: {
                    insured: "assuré",
                    insuredMore: "dont {qty} assurés",
                    insuredOne: "dont 1 assuré",
                },
                de: {
                    insured: "versichert",
                    insuredMore: "davon {qty} versichert",
                    insuredOne: "davon 1 versichert",
                },
            },
        };
    }
};
</script>

<style scoped lang="scss">
.purchase-item {
    border-radius: 10px;
    padding: 1em;
    background-color: #f9f9f950;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10pt;
}

.purchase-item.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.purchase-image {
    width: 90px;
    height: 90px;
    object-fit: contain;
    margin: 0;
    margin-bottom: 1em;
}

.insured-label {
    width: 40px;
    height: 40px;
    position: relative;
    top: -40px;
    left: 30px;
    margin-bottom: -40px;
}
</style>