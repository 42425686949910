<template>
    <svg class="coin-icon" width="1em" height="1em" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_12041_215)">
            <path d="M25.4501 14.9951L14.9961 4.53772L4.54205 14.9951L14.9961 25.4524L25.4501 14.9951Z" fill="currentColor"/>
            <path d="M14.9978 29.9999C14.8176 29.9999 14.6421 29.9288 14.5094 29.8007L0.199285 15.4814C-0.0709855 15.211 -0.0709855 14.7794 0.199285 14.509L14.5141 0.194414C14.7702 -0.0617123 15.2301 -0.0617123 15.4861 0.194414L29.7962 14.509C29.9243 14.6371 29.9954 14.8126 29.9954 14.9976C29.9954 15.1826 29.9243 15.3533 29.7962 15.4861L15.4861 29.8007C15.3581 29.9288 15.1827 29.9999 14.9978 29.9999ZM1.65969 14.9976L14.9978 28.3399L28.3358 14.9976L14.9978 1.65054L1.65969 14.9976Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_12041_215">
                <rect width="30" height="30" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "CoinIcon",
};
</script>

<style scoped>
.coin-icon {
    display: inline-block;
    
    padding: 0;
    margin-bottom: -0.12em;
}
</style>
