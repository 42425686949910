<template>
  <div class="background-image">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>

    <DebugHeader v-if="isDebugMode"
      :isScreensaver="$route.name === 'screensaver'"
      @debug-card-on="onCardDataReceived"
      @debug-card-off="onCardOff"
      @debug-popup="debugPopup"
    />

    <router-view />

    <div v-if="showPopup" class="popup">
      {{ popupMessage }}
    </div>
  </div>
</template>

<script>

import { readCard } from './service/card';
import { simulateItem, getDebugCard } from './service/debugCard';
import defs from "./service/definitions";
import DebugHeader from "@/components/DebugHeader.vue";

export default {
  name: 'App',
  components: {
    DebugHeader,
  },  
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    isLoading() {
      return this.$store.state.loading;
    },
    allPurchasesMap() {
      return defs.purchaseMap || {};
    },
  },
  data() {
    return {
      showPurchaseList: false,
      showPopup: false,
      popupMessage: ''
    };
  },
  mounted() {
    this.$router.isReady().then(() => {

    });
    this.startLongPolling();
  },
  methods: {
    goToHome() {
      if (this.$route.name == 'avatar') {
        this.$router.push({ name: 'screensaver' });
      } else {
        this.$router.push({ name: 'avatar' });
      }

    },
    goToAvatar() {
      this.$router.push({ name: 'avatar' });
    },
    goToRound() {
      this.$router.push({ name: 'profile' });
    },
    goToScreenSaver() {
      this.$router.push({ name: 'screensaver' });
    },

    onDebugCardOn() {
      this.onCardDataReceived(getDebugCard());
    },

    onDebugPurchase(code) {
      let purchase = defs.purchaseMap[code];
      purchase.code = parseInt(code);
      let result = simulateItem(purchase);

      console.log(result);
      this.togglePurchasesList();

      this.debugPopup(result);

    },

    debugPopup( message ){
      this.popupMessage = message;
      this.showPopup = true;

      // Masquer la popup après 1 seconde
      setTimeout(() => {
        this.showPopup = false;
      }, 1000);
    },

    onCardOff() {
      this.goToScreenSaver();
    },

    onCardDataReceived(rawData) {
      let card = null;

      try {
        card = readCard(rawData);

        this.$store.commit('setCard', card);

        console.log("onCardDataReceived :", card);

        if (card.currentRound > 0) {
          this.goToRound();
        } else {
          this.goToAvatar();
        }

      } catch ( err ) {
        this.debugPopup("Card is corrupted");
        
        this.$store.commit('setCard', null);

        console.error(err);

        this.goToAvatar();
      }
    },

    togglePurchasesList() {
      this.showPurchaseList = !this.showPurchaseList;
    },

    startLongPolling() {
      const poll = async () => {

        try {
          const response = await fetch("http://localhost/card_event");
          if (response.ok) {
            const data = await response.json();

            if (data.event === "new card") {


              this.onCardDataReceived(data.raw);

            } else {
              console.log("Événement reçu :", data);

              this.onCardOff();
            }

          } else {
            console.error("Erreur lors du long polling:", response.status);

          }
          this.$store.dispatch('disableDebugMode');

          poll();
        } catch (error) {
          console.error("Erreur réseau lors du long polling:", error);
          this.$store.dispatch('enableDebugMode');
          setTimeout(poll, 5000);
        }
      }
      poll();
    },
  }
}
</script>


<style lang="scss">
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('@/assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('@/assets/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('@/assets/fonts/PlusJakartaSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Funcom';
  src: url('@/assets/fonts/Funcom.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

.primary-input {
  font-size: large;
  font-weight: bold;
  color: $primary-color;
  height: 3em;
  border-radius: 1.5em;
  border: 0;
  padding: 1em 1.5em;
}

button.primary-input {
  font-size: larger;
  color: $secondary-color;
}

.secondary-input {
  height: 3em;
  border: 1px solid $white;
  border-radius: 1.5em;
  color: $white;
  background-color: transparent;
  margin: 1em;
  padding: 1em;
  font-weight: bold;
  font-size: medium;

  &.active {
    color: $secondary-color;
    border-color: $secondary-color;
  }
}

body {
  margin: 0px;
  overflow: hidden;
}

div.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.debug-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  background-color: #f8d7da;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.debug-button {
  background-color: red;
  color: white;
  padding: 0.5em;
  border: none;
  cursor: pointer;
  margin: 0.5em;
}

.debug-dropdown {
  display: inline-block;
  position: relative;

  .purchase-list {
    position: absolute;
    top: 2.5em;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    padding: 0.5em;
    text-align: left;
    width: 25em;

    .purchase-item {
      color: black;
      margin: 0.25em 0;
      cursor: pointer;
      flex-direction: row;
    }
  }
}

div.content {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.footer {
  font-weight: bold;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: small;
  color: $light-text-color;
}

.popup {
  font-size: 3em;
  width: 20vw;
  position: fixed;
  top: 20vh;
  right: 40vw;
  background: #ffffff;
  color: #000000;
  padding: 1em;
  border: 1px solid #000000;
  z-index: 9999;
}

#app {
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $white;
}

.background-image {
  background-image: url('@/assets/background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
