<template>
    <span class="interpolated-value">
      {{ displayValue }}
    </span>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentValue: this.value,
      displayValue: this.value,
      animationFrame: null
    };
  },
  watch: {
    value(newValue) {

      if (this.currentValue != null && newValue != null) {
        this.animateValue(newValue);
      }else{
        this.displayValue = newValue;
        this.currentValue = newValue;
      }
    }
  },
  methods: {
    /**
     * Fonction d'easing : Ralentissement exponentiel (ease-out)
     * @param {number} t - Progression de 0 à 1
     * @returns {number} - Progression ajustée
     */
    easeOutExpo(t) {
      return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
    },
    animateValue(newValue) {
      const duration = 3000; // Durée de l'animation en millisecondes
      const startValue = this.currentValue;
      const difference = newValue - startValue;
      const startTime = performance.now();

      const step = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1); // Progression entre 0 et 1
        const easedProgress = this.easeOutExpo(progress); // Applique l'easing
        this.displayValue = Math.round(startValue + difference * easedProgress);

        if (progress < 1) {
          this.animationFrame = requestAnimationFrame(step);
        } else {
          this.currentValue = newValue; // Animation terminée
        }
      };

      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
      }

      this.animationFrame = requestAnimationFrame(step);
    }
  },
  mounted(){
    this.currentValue = null;
  },  
  beforeUnmount() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
    }
  }
};
</script>

<style scoped>

</style>