<template>
    <div v-if="list.length > 0" class="generic-field">
        <span class="header"> {{title}}&nbsp;:&nbsp;</span>
        <template v-for="(item, id) in list" :key="id">
            <span v-if="id > 0">-&nbsp;</span>
            <span class="item" >{{ item }}&nbsp;</span>
        </template>
    </div>
</template>

<script>

export default {
    name: "GenericField",
    props: {
        title: {
            type: String,
            required: true,
        },
        list: {
            type: [],
            required: true,
        }
    },
};
</script>

<style scoped lang="scss">

.generic-field{
    background-color: white;
    border-radius: 10px;
    color: $primary-color;
    font-size: 16pt;
    margin: auto;
    margin-top: 30px;
    padding: 1em 0;
    text-align: center;    
    width: $general-width;
}

.header{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: bold;
}

.item{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: light;
}
</style>