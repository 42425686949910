/* eslint-disable */

// making cfc
const round1 = [
    "0101000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "12496D704F545300000000000000000000",
]

// making certificate
const round2 = [
    "010400450104161A000000000000000000",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "0506404900000000000000000000000000",
    "0606A45100000000000000000000000000",
    "080DDE5200000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "1266736466000000000000000000000000",
]

// bachelor not ready
const all = [
    "0105004D0104161A190000000000000000",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "0506404900000000000000000000000000",
    "0606A45100000000000000000000000000",
    "080DDE5200000000000000000000000000",
    "090C8001020304090A9192000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "1266736466000000000000000000000000",
]

const round4Loose = [
    "01040051011A1516000000000000000001",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "0506A449F2000000000000000000000000",
    "06070859A9000000000000000000000000",
    "0800C80199490000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "12496D704F545300000000000000000000",
]

const round5 = [
    "01050051011A1504190000000000000000",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "0506A449F2000000000000000000000000",
    "06076C5900000000000000000000000000",
    "0809C40000000000000000000000000000",
    "09125CF200000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "12496D704F545300000000000000000000",
]

const round6 = [
    "01060051021A1504191700000000000001",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "0506A449F2000000000000000000000000",
    "06076C5900000000000000000000000000",
    "0809C40000000000000000000000000000",
    "090834F39949A900000000000000000000",
    "0A060E9B00000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "12496D704F545300000000000000000000",
]

const testHouse = [
    "0105004501131A19140000000000000000",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "05085C4900000000000000000000000000",
    "06096C5100000000000000000000000000",
    "08151ED911000000000000000000000000",
    "0907AD919B0299D9000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "12546573745F484F555345000000000000",
]

const endOfGame = [
    "010A00000014151719161B13041A000000",
    "0000000000000000000000000000000000",
    "0405DC0000000000000000000000000000",
    "0507080000000000000000000000000000",
    "0608980000000000000000000000000000",
    "0809600000000000000000000000000000",
    "090A8C0000000000000000000000000000",
    "0A0AF00000000000000000000000000000",
    "0C0A8C0000000000000000000000000000",
    "0D0BB80000000000000000000000000000",
    "0E0A280000000000000000000000000000",
    "100A280100000000000000000000000000",
    "1100000000000000000000000000000000",
    "1278780000000000000000000000000000",
]

const corruptedCard = [
    "0104004502131A19000000000000000000",
    "0000000000000000000000000000000000",
    "0405DC4100000000000000000000000000",
    "05085C4900000000000000000000000000",
    "06096C5100000000000000000000000000",
    "0807123100000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "0000000000000000000000000000000000",
    "1100000000000000000000000000000000",
    "12546573745F484F555345000000000000",
]

const debugCardHex = null;

export default debugCardHex;