export default [
  "Centre Scolaire Crans-Montana",
  "CO Anniviers",
  "CO Ayent",
  "CO des Collines",
  "CO Collombey-Muraz",
  "CO Derborence",
  "CO ERVEO (Orsières)",
  "CO des 2 Rives",
  "CO des Liddes",
  "CO Fully-Saxon",
  "CO Goubing",
  "CO Grône",
  "CO Haut-Lac",
  "CO Hérens",
  "CO Monthey",
  "CO Nendaz",
  "CO Octodure",
  "CO Savièse",
  "CO St-Guérin",
  "CO Troistorrents EIVI",
  "CO Val de Bagnes",
  "Collège de la Tuilerie",
  "ECCG Sierre",
  "EPP de Sion",
  "Institut Notre-Dame de Lourdes",
  "Institut St-Raphael",
  "Kinderdorf Leuk BWS",
  "OS Brig Süd",
  "OS Gampel",
  "OS Goubing, Siders",
  "OS Leuk",
  "OS Lötschental",
  "OS Naters",
  "OS Raron",
  "OS Saas",
  "OS Stalden",
  "OS St. Niklaus",
  "OS Untergoms",
  "OS Visp",
  "OS Zermatt",
  "Schule Aletsch",
  "Schule Leukerbad",
  "Tagesschul Münster"
];
  