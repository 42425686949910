// store/index.js
import Player from '@/service/player';
import { createStore } from 'vuex';

const packageVersion = process.env.VUE_APP_PACKAGE_VERSION || '0.0.0';
const splits = packageVersion.split('.');
let appVersion;

if (splits[2] === '0') {
    appVersion = splits[0] + '.' + splits[1];
} else {
    appVersion = packageVersion;
}

const store = createStore({
    state() {
        return {
            packageVersion: appVersion,
            loading: false,
            debugMode: false,
            player: null
        };
    },
    mutations: {
        setLoading(state, isLoading) {
            state.loading = isLoading;
        },
        setCard(state, card) {
            state.player = new Player(card);
        },
        setDebugMode(state, status) {
            state.debugMode = status;
        }
    },
    actions: {
        setPlayer({ commit }, data) {
            commit('setCard', data);
        },
        enableDebugMode({ commit }) {
            commit('setDebugMode', true);
        },
        disableDebugMode({ commit }) {
            commit('setDebugMode', false);
        },
        startLoading({ commit }) {
            commit("setLoading", true);
        },
        stopLoading({ commit }) {
            commit("setLoading", false);
        },
    }
});

export default store;
