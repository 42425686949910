<template>
  <div class="game-header">
    <div class="player-profile">
      <div class="left">
        <AvatarDisplay class="avatar" :avatar="player.getAvatar()" :accessory="player.getAccessory()" />
      </div>
      <div class="right">
        <div class="pseudo">{{ player.getPseudo() }}</div>
        <div class="age">{{ age }} {{ translations[selectedLanguage].years }}</div>
      </div>
    </div>

    <ProgressBar :value="score" />
    <LanguageSelector :selectedLanguage="selectedLanguage" @changeLanguage="changeLanguage" />

  </div>
</template>

<script>
import AvatarDisplay from "@/components/AvatarDisplay.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  name: "GameHeader",
  props: {
    player: {
      type: Object,
      required: true,
    },
    age: {
      type: Number,
      required: true,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      translations: {
        fr: {
          years: "ans",
        },
        de: {
          years: "Jahre",
        },
      }
    }
  },
  components: {
    AvatarDisplay,
    ProgressBar,
    LanguageSelector,
  },
  methods: {
    changeLanguage(lang) {
      this.$emit("changeLanguage", lang);
    },
  },
};
</script>

<style scoped>
.game-header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.player-profile {
  font-family: "Funcom", sans-serif;
  font-size: large;
  display: grid;
  grid-template-columns: 80px 180px;
  gap: 1em;
  align-items: center;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.score {
  margin-top: 3em;
}

.pseudo {
  font-weight: normal;
  font-size: 1.5em;
}

.age {
  font-size: 1.2em;
}

.avatar {
  width: 100px;
  aspect-ratio: 1;
}
</style>