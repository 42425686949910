<template>
    <div class="purchases-container">
        <h1>{{ translations[selectedLanguage].title }}</h1>
        <div class="purchases-grid">
            <PurchaseItem v-for="(purchase, id) in purchases" :key="id" :item="getPurchaseById(id)" :selectedLanguage="selectedLanguage" :possession="purchase" />
        </div>
    </div>
</template>

<script>
import definitions from "@/service/definitions";
import PurchaseItem from "@/components/PurchaseItem.vue";

export default {
    name: "RoundPurchasesGroup",
    props: {
        selectedLanguage: {
            type: String,
            required: true,
        },
        purchases: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            translations: {
                fr: {
                    title: "Mes actions de ce tour",
                },
                de: {
                    title: "Meine Aktionen dieser Runde",
                },
            },
        }
    },
    components: {
        PurchaseItem,
    },
    methods: {
        getPurchaseById(id) {
            return Object.values(definitions.purchaseMap).find(item => item.id === id) || {};
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/PurchasesGrid.scss"; 
</style>