<template>

  <GameHeader :player="player" :age="age" :score="score" :selectedLanguage="selectedLanguage"
    @changeLanguage="changeLanguage" />

  <template v-if="state == 'PROFILE'">

    <h1>
      {{ translations[selectedLanguage].title }}
    </h1>

    <AllPurchasesGroup :selectedLanguage="selectedLanguage" :possessions="possessions" />

    <RoundPurchasesGroup :selectedLanguage="selectedLanguage" :purchases="actions" />

    <GenericField :title="translations[selectedLanguage].education" :list="education" />

    <GenericField :title="translations[selectedLanguage].missingInsurances" :list="missingInsurances" />

    <GenericField :title="translations[selectedLanguage].missingMortgages" :list="missingMortgages" />

  </template>

  <template v-if="state == 'CLOSE'">

    <h1>
      Clôture
    </h1>

    <h2>Impôts : {{ player.getTax().amount }}</h2>

    <h3>Revenu imposable : {{ player.getTax().taxableAmount }}</h3>
    <ul>
      <li v-for="detail in player.getTax().details" :key="detail.id">
        {{ detail['description-fr'] }} : {{ detail.amount }}
      </li>
    </ul>

    <h2>Dépenses : {{ player.getOutcomes().amount }}</h2>
    <ul>
      <li v-for="detail in player.getOutcomes().details" :key="detail.id">
        {{ detail['description-fr'] }} : {{ detail.amount }}
      </li>
    </ul>
  </template>

  <template v-if="state == 'RISK'">

    <div v-if="risk">
      <h1>
        {{ risk["title-fr"] }}
      </h1>
      <p>{{ risk["desc-fr"] }}</p>
      <p v-if="risk.amount > 0"> Tu gagnes {{ risk.amount }}!</p>
      <p v-else-if="risk.amount < 0"> Tu perds {{ -risk.amount }}!</p>

    </div>
    <div v-else>
      <h1>Aucun aléa</h1>
    </div>
  </template>

  <template v-if="state == 'INCOMES'">

    <h1>{{ translations[selectedLanguage].incomes }}</h1>

    <h2>Salaire : {{ player.getSalary().amount }}</h2>
    <ul>
      <li v-for="detail in player.getSalary().details" :key="detail.id">
        {{ detail.education }} : {{ detail.amount }}
      </li>
    </ul>

    <h2>Revenus : {{ player.getIncomes().amount }}</h2>
    <ul>
      <li v-for="detail in player.getIncomes().details" :key="detail.id">
        {{ detail["description-fr"] }} : {{ detail.amount }}
      </li>
    </ul>

    <h2>Score : {{ player.getScore().amount }}</h2>
    <ul>
      <li v-for="detail in player.getScore().details" :key="detail.id">
        {{ detail["description-fr"] }} : {{ detail.amount }}
      </li>
    </ul>

  </template>

  <template v-if="state == 'NEXT'">

    <h1>{{ translations[selectedLanguage].roundStart }}</h1>

    <AllPurchasesGroup :selectedLanguage="selectedLanguage" :possessions="possessions" />

    <GenericField :title="translations[selectedLanguage].education" :list="education" />

    <GenericField :title="translations[selectedLanguage].missingInsurances" :list="missingInsurances" />

    <GenericField :title="translations[selectedLanguage].missingMortgages" :list="missingMortgages" />

  </template>

  <template v-if="state == 'LOOSE'">

    <h1>{{ translations[selectedLanguage].loose }}</h1>

    <AllPurchasesGroup :selectedLanguage="selectedLanguage" :possessions="possessions" />

  </template>

  <template v-if="state == 'WIN'">

    <h1>{{ translations[selectedLanguage].win }}</h1>

    <AllPurchasesGroup :selectedLanguage="selectedLanguage" :possessions="possessions" />

  </template>

  <template v-if="state == 'BEGIN'">

    <h1>{{ translations[selectedLanguage].begin }}</h1>

    <AllPurchasesGroup :selectedLanguage="selectedLanguage" :possessions="possessions" />

  </template>

  <!-- <h2>Score : {{ player.getScore().amount }}</h2>
  <ul>
    <li v-for="detail in player.getScore().details" :key="detail.id">
      {{ detail["description-fr"] }} : {{ detail.amount }}
    </li>
  </ul> -->

  <div class="game-controls">

    <div id="control-1" @click="displayComputingDetails">

      <AmountFooter :selectedLanguage="selectedLanguage" :grandAmount="amount" :outcomes="outcomes" :taxes="taxes" />
    </div>

    <div id="control-2">
      <CardInstruction v-if="state == 'PROFILE'" :text="translations[selectedLanguage].removeCardBack" />
      <CardInstruction v-if="state == 'NEXT'" :text="translations[selectedLanguage].removeCard" />
      <CardInstruction v-if="state == 'BEGIN'" :text="translations[selectedLanguage].removeCardBegin" />
    </div>

    <div id="control-3">

      <NextButton v-if="state == 'PROFILE'" :label="translations[selectedLanguage].finishRound" @click="toClose" />
      <NextButton v-if="state == 'CLOSE'" :label="translations[selectedLanguage].continue" @click="toRisk" />
      <NextButton v-if="state == 'RISK'" :label="translations[selectedLanguage].continue" @click="toIncomes" />
      <NextButton v-if="state == 'INCOMES'" :label="translations[selectedLanguage].continue" @click="toNext" />
      <NextButton v-if="state == 'LOOSE'" :label="translations[selectedLanguage].restartGame" @click="restart" />
      <NextButton v-if="state == 'WIN'" :label="translations[selectedLanguage].restartGame" @click="restart" />
    </div>

    <div id="control-4">
      <div v-if="state == 'PROFILE'" class="quit-game" @click="restart">{{ translations[selectedLanguage].restartGame }}
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { resetGame } from "@/service/card";
import defs from "@/service/definitions"
import AllPurchasesGroup from "@/components/AllPurchasesGroup.vue";
import AmountFooter from "@/components/AmountFooter.vue";
import CardInstruction from "@/components/CardInstruction.vue";
import GameHeader from "@/components/GameHeader.vue";
import GenericField from "@/components/GenericField.vue";
import NextButton from "@/components/NextButton.vue";
import RoundPurchasesGroup from "@/components/RoundPurchasesGroup.vue";

export default {
  components: {
    AllPurchasesGroup,
    AmountFooter,
    CardInstruction,
    GameHeader,
    GenericField,
    NextButton,
    RoundPurchasesGroup
  },
  computed: {
    ...mapState({
      player: (state) => state.player,
    }),
    selectedLanguage() {
      return this.player.getLanguage() == defs.de ? 'de' : 'fr';
    },
    education() {
      let education = [];
      let ids = {};

      for (let formation of this.player.getEducation()) {

        if (!ids[formation.id]) {
          ids[formation.id] = {
            qty: 0,
            item: formation[`description-${this.selectedLanguage}`]
          }
        }

        ids[formation.id].qty++;
      }

      for (let id in ids) {

        if (ids[id].qty > 1) {
          education.push(ids[id].qty + " x " + ids[id].item);
        } else {
          education.push(ids[id].item);
        }
      }

      return education;
    },
    missingInsurances() {
      return this.player.getUninsuredPurchases().map((item) => item[`description-${this.selectedLanguage}`]);
    },
    missingMortgages() {
      return this.player.getUnusedMortgages().map((item) => item[`description-${this.selectedLanguage}`]);
    },
    risk() {
      return this.player.lastRisk;
    },
    possessions() {

      let value = {};

      // all purchases
      for (let purchase of this.player.getAllPurchases()) {

        let normalizedId = purchase.item.id.replace("insured-", "").replace("-with-mortgage", "");

        if (!value[normalizedId]) {
          value[normalizedId] = {
            qty: 0,
            insuredQty: 0
          }
        }

        if (purchase.item.id.includes("insured-")) {
          value[normalizedId].insuredQty += purchase.qty;
          value[normalizedId].qty += purchase.qty;
        } else {
          value[normalizedId].qty += purchase.qty;
        }
      }

      return value;
    }
  },
  data() {
    return {
      actions: {},
      age: 0,
      amount: null,
      score: null,
      outcomes: 0,
      taxes: 0,
      state: 'PROFILE',
      translations: {
        fr: {
          title: "Résumé du tour en cours",
          education: "Formations terminées",
          missingInsurances: "Assurances manquantes",
          missingMortgages: "Hypothèques non finalisées",
          removeCardBack: "Retire la carte pour retourner à tes achats",
          removeCard: "Retire la carte pour continuer",
          finishRound: "Terminer le tour",
          restartGame: "Recommencer le jeu",
          continue: "Continuer",
          incomes: "Revenus",
          roundStart: "En route pour le tour suivant !",
          loose: "Perdu !",
          win: "Bravo, la partie est terminée !",
          begin: "C'est parti !",
          removeCardBegin: "Retire ta carte et va faire des achats",
        },
        de: {
          title: "Zusammenfassung der laufenden Runde",
          education: "Abgeschlossene Ausbildungen",
          missingInsurances: "Fehlende Versicherungen",
          missingMortgages: "Unvollendete Hypotheken",
          removeCardBack: "Entferne die Karte, um zu deinen Einkäufen zurückzukehren",
          removeCard: "Entferne die Karte, um fortzufahren",
          finishRound: "Runde beenden",
          restartGame: "Spiel neu starten",
          continue: "Fortsetzen",
          incomes: "Einnahmen",
          roundStart: "Auf geht's in die nächste Runde!",
          loose: "Verloren!",
          win: "Glückwunsch, das Spiel ist beendet!",
          begin: "Los geht's!",
          removeCardBegin: "Entferne deine Karte und mache Einkäufe",
        },
      },
    }
  },
  methods: {
    async toClose() {

      if (this.state != 'CLOSE') {
        this.state = 'CLOSE';

        this.amount = this.player.amount;
        await this.player.startNextRound();
        this.outcomes = 0;
        this.taxes = 0;
        this.amount = this.player.afterCloseAmount;
      }

    },
    toRisk() {

      if (this.player.afterCloseAmount < 0) {
        this.state = 'LOOSE';
      } else {
        this.state = 'RISK';
        this.amount = this.player.afterRiskAmount;
      }
    },
    toIncomes() {

      if (this.player.afterRiskAmount < 0) {
        this.state = 'LOOSE';
      } else if (this.player.currentRound > defs.game.lastRound) {
        this.state = 'WIN';
        this.score = this.player.getScore().amount;
      } else {
        this.state = 'INCOMES';
        this.amount = this.player.amount;
        this.age = this.player.getAge();
      }
    },
    toNext() {
      this.state = 'NEXT';
      this.amount = this.player.amount;
      this.outcomes = this.player.getOutcomes().amount;
      this.taxes = this.player.getTax().amount;
      this.score = this.player.getScore().amount;
    },

    async restart() {

      this.$store.dispatch("startLoading");
      await resetGame();
      this.$router.push({ name: 'avatar' });
      this.$store.dispatch("stopLoading");
    },

    changeLanguage(lang) {
      this.player.setLanguage(lang == 'de' ? defs.de : defs.fr);
    },

    displayComputingDetails() {

      console.log("Impôts : ", this.player.getTax().amount);
      console.log("Imposable : ", this.player.getTax().taxableAmount);
      for (let detail of this.player.getTax().details) {
        console.log(detail['description-fr'], " : ", detail.amount);
      }
    },
  },
  mounted() {

    if (this.$route.name === 'next') {

      this.state = 'BEGIN';
      this.age = 15;
      this.amount = 1500;
      this.outcomes = 0;
      this.taxes = 300;
      this.score = 0;

    } else {


      this.age = this.player.getAge();
      this.amount = this.player.amount;
      this.outcomes = this.player.getOutcomes().amount;
      this.taxes = this.player.getTax().amount;
      this.score = this.player.getScore().previous;


      if (this.amount == 0) {
        this.state = 'LOOSE';
      }

      // actions this round
      for (let purchase of this.player.getActionsThisRound()) {

        let normalizedId = purchase.id.replace("insured-", "");

        if (!this.actions[normalizedId]) {
          this.actions[normalizedId] = {
            qty: 0,
            insuredQty: 0
          }
        }

        if (purchase.id != normalizedId) {
          this.actions[normalizedId].insuredQty += 1;
          this.actions[normalizedId].qty += 1;
        } else {
          this.actions[normalizedId].qty += 1;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Funcom", sans-serif;
  font-size: 38pt;
  font-weight: normal;
  margin-top: 80px;
}

.game-controls {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 75%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;

  &>div {
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.quit-game {
  margin-top: 2.5em;
  text-decoration: underline;
}
</style>