<template>
    <div class="purchases-container">
        <h1>{{ translations[selectedLanguage].title }}</h1>
        <div class="purchases-grid">
            <PurchaseItem v-for="purchase in purchaseItems" :key="purchase.id" :item="purchase" :selectedLanguage="selectedLanguage" :possession="possessions[purchase.id]" />
        </div>
    </div>
</template>

<script>
import definitions from "@/service/definitions";
import PurchaseItem from "@/components/PurchaseItem.vue";

export default {
    name: "AllPurchasesGroup",
    props: {
        selectedLanguage: {
            type: String,
            required: true,
        },
        possessions: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            translations: {
                fr: {
                    title: "Mes possessions",
                },
                de: {
                    title: "Meine Besitztümer",
                },
            },
        }
    },
    components: {
        PurchaseItem,
    },
    computed: {
        purchaseItems() {
            return Object.values(definitions.purchaseMap).filter(item => (item.purchase && !item['require-mortgage']));
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/PurchasesGrid.scss"; 
</style>