import { startRound, startGame } from "./card";
import defs from "./definitions";

export default class Player {
    constructor(card) {

        this.card = card;

        this.lastRisk = null;
        this.afterCloseAmount = 0;
        this.afterRiskAmount = 0;
        this.lastRoundPoints = 0;
    
        if (this.card != null) {
            this.riskEvents = this.card?.events || [];
            this.amount = this.card?.rounds[this.card?.currentRound]?.amount | 0;
            this.reset();
            this.computeAllPurchases();
            this.currentRound = this.card.currentRound;
        }
    }

    reset = function () {
        this.salary = null;
        this.outcomes = null;
        this.incomes = null;
        this.resetPurchases(); 
        this.currentRound = 0;      
    }

    resetPurchases = function () {
        this.investments = 0;
        this.pension = 0;
        this.roundPension = 0;
        this.lifeInsuranceNumber = 0;
        this.lifeInsurance = 0;
        this.purchases = [];
        this.uninsuredPurchases = [];
        this.unusedMortgages = [];
        this.pendingEducation = null;
        this.education = [];
    }

    applyARisk = function () {
        let possibleRisks = [];
        let selectedRisk = null;

        // Parcourt les risques définis dans defs.risks
        for (const riskKey in defs.risks) {
            const risk = defs.risks[riskKey];
            risk.code = parseInt(riskKey);

            if (this.riskEvents.some(event => event.id === risk.id)) {

                // event allready happens

            } else if (this.purchases.some(purchase => purchase.item.id === risk.excludedBy)) {

                // risk exluded by a purchase

            } else if (risk.target == null) {

                possibleRisks.push(risk);

            } else if (this.purchases.some(purchase => purchase.item.id === risk.target)) {

                possibleRisks.push(risk);
            }
        }

        // Retourne un risque aléatoire parmi les risques possibles ou null si aucun risque applicable
        if (possibleRisks.length > 0) {
            const randomIndex = Math.floor(Math.random() * possibleRisks.length);
            selectedRisk = possibleRisks[randomIndex];
        }

        this.riskEvents.push(selectedRisk);
        this.amount += selectedRisk.amount;

        this.computeAllPurchases();

        return selectedRisk;
    };

    computeAllPurchases = function () {

        console.log('computeAllPurchase');

        let ids = [];
        this.resetPurchases();

        for (let i = 1; i <= this.card.currentRound; i++) {
            
            this.lastRoundPoints = 0;
            
            let roundObject = this.card.rounds[i];

            this.investments += roundObject.investments;

            this.pension += roundObject.pension;
            this.pension = parseFloat(this.pension * 1.2);

            this.lifeInsuranceNumber += roundObject.lifeInsurance;
            this.lifeInsurance += this.lifeInsuranceNumber;
            this.lifeInsurance = parseFloat(this.lifeInsurance * 1.25);

        

            if (roundObject.round == this.card.currentRound) {
                this.roundPension = roundObject.pension;
            }

            for (const purchase of roundObject.purchases) {

                if (purchase.formation) {

                    if (roundObject.round == this.card.currentRound) {
                        this.pendingEducation = purchase;

                    } else if ((roundObject.round == this.card.currentRound - 1) && (purchase.id == "bachelor" || purchase.id == "master")) {

                        purchase.complete = false;
                        this.education.push(purchase);

                    } else {

                        purchase.complete = true;
                        this.education.push(purchase);
                    }

                } else if ((purchase.mobility || purchase.property) && !purchase.insured) {

                    // ignore uninsured purchases from older rounds
                    if (roundObject.round == this.card.currentRound) {
                        this.uninsuredPurchases.push(purchase);
                    }

                } else if (purchase.mortgage) {

                    // ignore unused mortgages from older rounds
                    if (roundObject.round == this.card.currentRound) {
                        this.unusedMortgages.push(purchase);
                    }

                } else {
                    let index = ids.indexOf(purchase.id);

                    if (index != -1) {
                        this.purchases[index].qty++;
                    } else {
                        this.purchases.push({
                            qty: 1,
                            item: purchase
                        });
                        ids.push(purchase.id);
                    }

                    this.lastRoundPoints += purchase.points;
                }
            }
        }

        for (const riskEvent of this.riskEvents) {

            if (riskEvent.delete) {
                let index = ids.indexOf(riskEvent.target);

                if (index != -1) {

                    this.purchases[index].qty--;

                    console.log('this.purchases[index]', this.purchases[index]);

                    if (this.purchases[index].qty == 0) {

                        this.purchases.splice(index, 1);
                        ids.splice(index, 1);
                    }
                } else {
                    console.error("SHOULD NOT HAPPEND");
                }
            }
        }
    }

    getActionsThisRound = function () {
        return this.card?.rounds[this.card.currentRound]?.purchases || [];
    };

    getAccessory = function () {
        return this.card?.accessoryId || 0;
    }

    getAge = function () {
        return parseInt((this.getCurrentRound() - 1) * 5 + 15);
    }

    getAllPurchases = function () {

        return this.purchases;
    };

    getAmount = function () {
        return this.amount;
    };

    getAvatar = function () {
        return this.card?.avatarId || 0;
    }

    getCurrentRound = function () {
        return this.currentRound;
    };

    getEducation = function () {
        return this.education;
    };

    getLanguage = function () {
        return this.card?.languageId || 0;
    }

    getPendingEducation = function () {

        return this.pendingEducation;
    };

    getPseudo = function () {
        return this.card.pseudo;
    }

    getSalary = function () {

        if (this.salary == null) {

            this.salary = {
                amount: defs.game.minSalary,
                details: []
            }

            if (this.getCurrentRound() == 1) {
                this.salary.amount = 0;
            } else {

                console.log('this.education', this.education)

                for (let edu of this.education) {

                    if (edu.complete == true) {

                        const amount = defs.game.salaryMap[edu.id];
                        if (amount !== undefined) {

                            this.salary.amount += amount;

                            this.salary.details.push({
                                education: edu,
                                amount: amount,
                            });
                        }
                    } else {

                        this.salary.details.push({
                            education: edu,
                            amount: 0,
                        });
                    }
                }
            }
        }

        return this.salary;
    };

    getScore = function () {
        if (this.score == null) {

            this.score = {
                amount: 0,
                previous: 0,
                details: []
            }

            for (const purchase of this.purchases) {
                
                console.log('purchase', purchase);
                const points = purchase.item.points * purchase.qty;
                this.score.amount += points;

                this.score.details.push({
                    "description-fr": purchase.qty + " x " + purchase.item["description-fr"],
                    "description-de": purchase.qty + " x " + purchase.item["description-de"],
                    amount: points
                });
            }

            for (const formation of this.education) {
                const points = formation.points;
                this.score.amount += points;

                this.score.details.push({
                    "description-fr": formation["description-fr"],
                    "description-de": formation["description-de"],
                    amount: points
                });
            }

            const item = defs.purchaseMap[defs.investmentsMask];
            const points = this.investments * item.points;
            this.score.amount += points;

            this.score.details.push({
                "description-fr": item['description-fr'],
                "description-de": item['description-de'],
                amount: points
            });

            const pensionItem = defs.purchaseMap[defs.pensionMask];
            const pensionPoints = parseInt(this.pension * pensionItem.points);
            this.score.amount += pensionPoints;

            this.score.details.push({
                "description-fr": pensionItem['description-fr'],
                "description-de": pensionItem['description-de'],
                amount: pensionPoints
            });

            const lifeInsuranceItem = defs.purchaseMap[defs.lifeInsuranceMask];
            const lifeInsurancePoints = parseInt(this.lifeInsurance * lifeInsuranceItem.points);
            this.score.amount += lifeInsurancePoints;

            this.score.details.push({
                "description-fr": lifeInsuranceItem['description-fr'],
                "description-de": lifeInsuranceItem['description-de'],
                amount: lifeInsurancePoints
            });

            this.score.previous = this.score.amount - this.lastRoundPoints;
        }

        return this.score;
    }

    getTax = function () {

        if (this.tax == null) {

            this.tax = {
                taxableAmount: 0,
                details: []
            }

            let lastRoundAmount = this.amount - this.getSalary().amount

            for( let action of this.getActionsThisRound() ){
                console.log('action', action);

                lastRoundAmount += action.cost;
            }
            
            this.tax.taxableAmount += lastRoundAmount;

            this.tax.details.push({
                "description-fr": "Capital",
                "description-de": "Capital",
                amount: this.tax.taxableAmount,
            });

            this.tax.taxableAmount += this.getSalary().amount;

            this.tax.details.push({
                "description-fr": "Salaire",
                "description-de": "Saläre",
                amount: this.getSalary().amount,
            })

            // + revenus locatifs
            // + revenus placements
            for (let income of this.getIncomes().details) {

                if (income.amount > 0) {

                    this.tax.taxableAmount += income.amount,

                        this.tax.details.push({
                            "description-fr": income["description-fr"],
                            "description-de": income["description-de"],
                            amount: income.amount
                        })
                }
            }

            // - formations
            if (this.pendingEducation) {
                this.tax.taxableAmount -= this.pendingEducation.cost

                this.tax.details.push({
                    "description-fr": this.pendingEducation["description-fr"],
                    "description-de": this.pendingEducation["description-de"],
                    amount: - this.pendingEducation.cost,
                });
            }

            // - hypothèque
            // - assurance vie
            for (let outcome of this.getOutcomes().details) {
                this.tax.taxableAmount -= outcome.amount,

                    this.tax.details.push({
                        "description-fr": outcome["description-fr"],
                        "description-de": outcome["description-de"],
                        amount: -outcome.amount
                    })
            }

            // - retraite
            const pensionItem = defs.purchaseMap[defs.pensionMask];
            const pensionAmount = this.roundPension * pensionItem.cost;
            this.tax.taxableAmount -= pensionAmount;

            this.tax.details.push({
                "description-fr": pensionItem['description-fr'],
                "description-de": pensionItem['description-de'],
                amount: -pensionAmount
            });

            this.tax.amount = parseInt(Math.round(this.tax.taxableAmount * defs.game.taxPercent / 10000) * 100);
        }

        return this.tax;

    };

    getUninsuredPurchases = function () {
        return this.uninsuredPurchases || [];
    }

    getUnusedMortgages = function () {
        return this.unusedMortgages || [];
    }

    updateEducation = function () {
        let educationByte = 0;
        let nCertificate = 0;

        const formationMap = {
            "cfc": 0,
            "maturity": 1,
            "license": 2,
            "diploma": 3,
            "bachelor": 4,
            "master": 5,
            "cfcPlus": 6,
            "maturityPlus": 7,
        };

        for (const edu of this.education) {
            const bitPosition = formationMap[edu.id];
            if (bitPosition !== undefined) {
                educationByte |= (1 << bitPosition);
            } else {
                nCertificate++;
            }

            if (edu.id === "cfc") {
                educationByte |= (1 << formationMap["cfcPlus"]);
            } else if (edu.id === "maturityPlus") {
                educationByte |= (1 << formationMap["maturityPlus"]);
            }

            edu.complete = true;
        }

        const edu = this.pendingEducation;

        if (edu != null) {
            const bitPosition = formationMap[edu.id];
            if (bitPosition !== undefined) {
                educationByte |= (1 << bitPosition);
            } else {
                nCertificate++;
            }

            if (edu.id == "bachelor" || edu.id == "master") {
                edu.complete = false;
            } else {
                edu.complete = true;
            }

            this.education.push(edu);
        }

        this.salary = null; // reset salary cache

        return { educationByte: educationByte, nCertificates: nCertificate };
    };


    getOutcomes = function () {

        if (this.outcomes == null) {
            this.outcomes = {
                amount: 0,
                details: []
            }

            let list = this.uninsuredPurchases.concat(this.unusedMortgages);

            for (let purchase of this.purchases) {

                if (purchase.item['require-mortgage'] == true) {

                    for (let i = 0; i < purchase.qty; i++) {
                        list.push(purchase.item);
                    }
                }
            }

            for (let purchase of list) {

                let amount = 0;

                if (purchase['require-mortgage']) {

                    amount = parseInt(purchase.cost * 0.1);
                } else if (purchase.mortgage) {

                    amount = purchase.mortgage;
                } else {
                    continue;
                }

                this.outcomes.details.push({
                    "description-fr": purchase["description-fr"],
                    "description-de": purchase["description-de"],
                    amount: amount
                });
                this.outcomes.amount += amount;

            }

            if (this.lifeInsuranceNumber > 0) {
                const item = defs.purchaseMap[defs.lifeInsuranceMask];
                let amount = this.lifeInsuranceNumber * item.cost;

                this.outcomes.details.push({
                    "description-fr": item["description-fr"] + " x " + this.lifeInsuranceNumber,
                    "description-de": item["description-de"] + " x " + this.lifeInsuranceNumber,
                    amount: amount
                });
                this.outcomes.amount += amount;
            }
        }

        return this.outcomes;

    }

    getIncomes = function () {

        if (this.incomes == null) {

            let nProperties = 0;

            this.incomes = {
                amount: 0,
                details: []
            }

            for (let purchase of this.purchases) {

                console.log('purchase', purchase)

                if (purchase.item.property == true) {

                    let amount = 0;

                    nProperties++;

                    if (nProperties > 1) {
                        amount = parseInt(purchase.item.cost * 0.2)
                    }

                    this.incomes.details.push({
                        "description-fr": "Propriété #" + nProperties + " (" + purchase.item["description-fr"] + ")",
                        amount: amount
                    })

                    this.incomes.amount += amount;
                }
            }

            if (this.investments > 0) {
                let investAmount = this.investments * 100;
                let amount = parseInt(investAmount * 0.15);

                this.incomes.details.push({
                    "description-fr": "Revenus des placements (15% de " + investAmount + ")",
                    amount: amount
                })
                this.incomes.amount += amount;
            }

        }

        return this.incomes;
    }

    setLanguage = function (languageId) {
        this.card.languageId = languageId;
    }

    startNextRound = async function () {

        console.log("startNextRound");

        this.amount -= this.getTax().amount;
        this.amount -= this.getOutcomes().amount;

        this.afterCloseAmount = this.amount;

        if (defs.game.enableRisk) {
            this.lastRisk = this.applyARisk();
        }

        this.afterRiskAmount = this.amount;

        let educationData = this.updateEducation();
        this.currentRound++;

        if(this.afterCloseAmount >= 0 && this.afterRiskAmount >= 0){
            this.amount += this.getSalary().amount;
            this.amount += this.getIncomes().amount;
        }else{
            this.amount = 0;
        }

        this.tax = null;

        let purchases = this.uninsuredPurchases.concat(this.unusedMortgages);

        // write new round on card
        startRound(this.amount, this.currentRound, this.getLanguage(), educationData.educationByte, educationData.nCertificates, this.riskEvents, purchases, this.investments, this.lifeInsuranceNumber);
    };

    startGame = async function (avatarID, accessoryID, languageID, pseudo) {

        if (this.card != null) {
            this.reset();
            this.card.currentRound = 0;
        }

        this.amount = defs.game.startAmount;

        await startGame(avatarID, accessoryID, languageID, pseudo, this.amount);
    };
}